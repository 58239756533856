<template>
  <div class="w-full z-10">
    <div class="flex justify-between items-center pb-3 mb-4">
      <h1 class="sm:text-2xl text-xl text-left text-blue-primary font-medium">
        {{ title }}
      </h1>
      <slot name="actions"></slot>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
  },
};
</script>

<style></style>
